<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                  <b-card-title>Votre Template d'attestation personnalisé</b-card-title>
              </b-card-header>
              <b-card-body>
                <b v-if="message && message !== ''" style="color: darkgreen">{{this.message}}</b>
                <b-form @submit.prevent="onSubmit">
                  <b-form-group>
                    <b-form-file
                        class="mb-1"
                        v-model="file"
                        @input="onFileChange"
                        accept="application/pdf"
                        placeholder="Sélectionner une template d'attestation"
                    ></b-form-file>
                  </b-form-group>
                  <div v-for="(item, index) in items" :key="index">
                    <b-form-group>
                      <b-row>
                        <b-col lg="6">
                          <b-form-input
                              class=""
                              v-model="item.key"
                              @input="checkInput(index)"
                              type="text"
                              placeholder="Variable (Ex: _filedesc)"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="6">
                          <b-form-input
                              class=""
                              v-model="item.value"
                              @input="checkInput(index)"
                              type="text"
                              placeholder="Valeur (Ex: Description)"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </div>
                  <b-button
                      class="mt-1"
                      type="button"
                      :disabled="isLoading"
                      @click="testCertificate"
                  ><span v-if="!isLoading">Tester</span><span v-if="isLoading">Chargement...</span></b-button>
                  <b-button
                      class="ml-1 mt-1"
                      type="submit"
                      :disabled="isLoading"
                  ><span v-if="!isLoading">Remplacer</span><span v-if="isLoading">Chargement...</span></b-button>
                  <b-button
                      class="ml-1 mt-1"
                      type="button"
                      :disabled="isLoading"
                  ><span v-if="!isLoading">Remise par défaut</span><span v-if="isLoading">Chargement...</span></b-button>
                </b-form>

              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="12" md="6" v-if="json._certificate">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Body Simplifié de votre requête</b-card-title>
              </b-card-header>
              <b-card-body >
                <pre style="border-radius: 10px; padding: 1em;" v-text="formattedJson"></pre>
                <b-button
                    class="mt-1"
                    type="button"
                    :disabled="!json"
                    @click="copyJson"
                >Copier le contenu JSON</b-button>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Prévisualiser votre attestation</b-card-title>
          </b-card-header>

          <b-card-body>
            <div>
              <iframe v-if="pdfBase64 && pdfBase64 !== ''" :src="(testPDFBase64 ? testPDFBase64 : pdfBase64)" style="width:100%; height:100vh;"></iframe>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>

    <b-row class="match-height">
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
    BFormFile,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormFile,
  },
  data() {
    return {
      json: {
        _hash: "Your hash",
        _certificate: null
      },
      data: '',
      file: null,
      key: '',
      pdfBase64: '',
      isLoading: false,
      testPDFBase64: '',
      message: '',
      items: [
        {key: '', value: ''}
      ]
    };
  },
  mounted() {
    this.privateKey = localStorage.getItem('privateKey') ;
    this.publicKey = localStorage.getItem('publicKey') ;

    axios.get(`https://apisb.openlogs.fr/api/certificate/me`, {
      headers: {
        "X-OPENLOGS-PUBKEY": this.publicKey,
        "X-OPENLOGS-PRIVKEY": this.privateKey
      }
    }).then(({data}) => {
      this.pdfBase64 = `data:application/pdf;base64,${data.data.certificate}`;
    }).catch((error) => {

    })
  },
  computed: {
    formattedJson() {
      return JSON.stringify(this.json, null, 4);
    }
  },
  methods: {
    copyJson() {
      navigator.clipboard.writeText(this.formattedJson)
          .then(() => {
            alert('JSON copié dans le presse papier !');
          })
          .catch(err => {
            console.error('Erreur lors de la copie !');
          });
    },
    checkInput(index) {
      const lastItem = this.items[this.items.length - 1];
      if (lastItem.key && lastItem.value && index === this.items.length - 1) {
        this.items.push({ key: '', value: '' });
      }
      let certificate = {};
      for (let item of this.items) {
        if (item.key === '') continue;
        certificate[item.key] = item.value;
      }
      this.json._certificate = certificate;
    },
    resetCertificateByDefault() {
      this.isLoading = true;
      axios.get(`https://apisb.openlogs.fr/api/certificate/update/default`, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        this.message = "Votre template d'attestation a bien été changé !";
        this.testPDFBase64 = "";
        this.isLoading = false;
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      });
    },
    onSubmit(e) {
      this.isLoading = true;
      e.preventDefault();
      if (this.pdfBase64 === '') {
        return;
      }
      axios.post(`https://apisb.openlogs.fr/api/certificate/update`, {
        certificate: this.pdfBase64.split(',')[1]
      }, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        this.message = "Votre template d'attestation a bien été changé !";
        this.testPDFBase64 = "";
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      });
    },
    testCertificate() {
      this.isLoading = true;
      if (!this.pdfBase64) {
        this.isLoading = false;
        return;
      }
      let content = {};
      for (let value of this.items) {
        if (value.key === '') continue;
        content[value.key] = value.value;
      }
      console.log(content);
      axios.post(`https://apisb.openlogs.fr/api/certificate/test`, {
        certificate: this.pdfBase64.split(',')[1],
        field: content,
      }, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        this.testPDFBase64 = `data:application/pdf;base64,${data.data.certificate}`;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      });
    },
    onFileChange(file) {
      if (file && file.type.startsWith('application/pdf')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pdfBase64 = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
